
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Basisplatzierung',
  components: {
    Modal,
  },
});
