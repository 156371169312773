<style lang="scss">
.basisplatzierung {
  .format-box {
    margin-bottom: 20px;
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
  .hinweis-box {
    padding: 15px;
    background: #eee;
  }
  .karte-bild-box {
    height: 350px;
    img {
      display: block;

      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .karte-stmk {
      width: 90%;
    }
  }
  .image-box {
    margin: 50px 0;
  }

  .themen-box {
    margin: 50px 0;
    .row {
      justify-content: center;
      align-self: center;
    }
    .col-lg-6,
    .col-lg-3 {
      margin-bottom: 30px;
      .bild-box {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 300px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
          -webkit-transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          transition: all 500ms ease;
        }
        .txt {
          width: 100%;
          position: absolute;
          text-align: center;
          left: 0;
          top: 50%;
          z-index: 2;
          color: #fff;
          font-size: 150%;
          height: 40px;
          margin-top: -20px;
          span {
            display: block;
            font-size: 50% !important;
            padding: 0 20px;
          }
        }
        &:hover {
          &:after {
            background-color: rgba($color: #000000, $alpha: 0);
          }
          .txt {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="basisplatzierung content container">
    <h1>Basisplatzierung</h1>

    <p class="intor-txt">Platzieren Sie Ihre Werbebotschaft im Hauptblatt der Kleinen Zeitung und profitieren Sie von lebensnahen Themenumfeldern unter der Woche und einer besonders hohen Aufmerksamkeit in der Sonntagsbeilage.</p>
    <div class="themen-box">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/basis-besser-leben.jpg')})` }">
            <div class="txt">Besser leben<span class="subtxt-v1">Recht &amp; Geld, Stil &amp; Trends, Körper &amp; Geist, Nachhaltig leben, Netz &amp; Technik </span><span class="subtxt-v2">MONTAG - SONNTAG</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/basis-kultur.jpg')})` }">
            <div class="txt">Kultur &amp; Medien <span class="subtxt-v2">MONTAG - SONNTAG</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/basis-mobilitaet.jpg')})` }">
            <div class="txt">Mobilität <span class="subtxt-v2">FREITAG + SONNTAG</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/basis-sonntagsbeilage.jpg')})` }">
            <div class="txt">Sonntagsbeilage<span>Familie, Heim &amp; Garten, Essen &amp; Trinken, Mode &amp; Lifestyle, uvm.</span><span class="subtxt-v2">SONNTAG</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/basis-sport.jpg')})` }">
            <div class="txt">Sport<span class="subtxt-v2">MONTAG - SONNTAG</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/basis-top-tipps.jpg')})` }">
            <div class="txt">Top-Tipps<span class="subtxt-v2">Reise (MONTAG), Shopping (DIENSTAG), Veranstaltungen (MITTWOCH, FREITAG), Essen &amp; Trinken (DONNERSTAG)</span></div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/basis-wohnen.jpg')})` }">
            <div class="txt">Wohnen <span class="subtxt-v2">FREITAG + MITTWOCH</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/themenschwerpunkt-gesundheit.jpg')})` }">
            <div class="txt">Gesundheit <span class="subtxt-v2">SAMSTAG</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/themenschwerpunkt-tourismus.jpg')})` }">
            <div class="txt">Reise <span class="subtxt-v2">SAMSTAG</span></div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/themenschwerpunkt-bildung.jpg')})` }">
            <div class="txt">Bildung &amp; Beruf <span class="subtxt-v2">SAMSTAG + MITTWOCH</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-12 col-md-12 text-center">
        <strong>Besser Leben</strong>
        <p>
          Montag. „Recht &amp; Geld“ (Ombudsfrau)<br />
          Mittwoch: „Nachhaltig Leben“*<br />
          Donnerstag: wechselnde Themen aus den Bereichen „Netz &amp; Technik“, „Stil &amp; Trends“, „Körper &amp; Geist“
        </p>
        <small>*vorbehaltlich redaktioneller Änderungen</small>
      </div>
    </div>
    <div class="whitespace"></div>

    <h2>Ihre Werbemöglichkeiten</h2>

    <div class="tabelle-box tabelle-width-50">
      <div class="headline">
        <strong>Millimeterpreis 4c</strong>
      </div>
      <div class="row head-row hide-small">
        <div class="col-lg-3"></div>
        <div class="col-lg-3 text-align-right">Kombination</div>
        <div class="col-lg-3 text-align-right">Steiermark</div>
        <div class="col-lg-3 text-align-right">Kärnten</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-center">MO - FR</div>
        <div class="col-6 text-align-left visible-small">Kombination</div>
        <div class="col-lg-3 col-6 text-align-right">20,68</div>
        <div class="col-6 text-align-left visible-small">Steiermark</div>
        <div class="col-lg-3 col-6 text-align-right">14,83</div>
        <div class="col-6 text-align-left visible-small">Kärnten</div>
        <div class="col-lg-3 col-6 text-align-right">9,07</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-center">SA - SO</div>
        <div class="col-6 text-align-left visible-small">Kombination</div>
        <div class="col-lg-3 col-6 text-align-right">21,88</div>
        <div class="col-6 text-align-left visible-small">Steiermark</div>
        <div class="col-lg-3 col-6 text-align-right">15,48</div>
        <div class="col-6 text-align-left visible-small">Kärnten</div>
        <div class="col-lg-3 col-6 text-align-right">9,47</div>
      </div>
    </div>

    <div class="row image-box">
      <div class="col-md-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/hauptblatt/basisplatzierung-besser-leben-v1.png" alt="Basisplatzierung Besser leben" class="img-fluid " />
      </div>
      <div class="col-md-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/hauptblatt/baissplatzierung-kultur-v1.png" alt="Basisplatzierung Kultur" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 47 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">1.209,78</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">867,56</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">530,60</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">1.279,98</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">905,58</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">554,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 98 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.419,56</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.735,11</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.061,19</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.559,96</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.811,16</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.107,99</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 200 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.839,12</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.470,22</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.122,38</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">5.119,92</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.622,32</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.215,98</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite**</strong>
            Fixformat: 98 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.258,68</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.205,33</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.183,57</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.679,88</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.433,48</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.323,97</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite**</strong>
            Fixformat: 47 x 260 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">6.989,84</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.012,54</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.065,66</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.395,44</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.232,24</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.200,86</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47 x 260" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite**</strong>
            Fixformat: 149 x 90 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.258,68</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.205,33</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.183,57</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.679,88</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.433,48</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.323,97</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x90.svg" alt="149x90" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite**</strong>
            Fixformat: 200 x 65 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">6.989,84</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.012,54</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.065,66</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.395,44</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.232,24</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.200,86</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x65.svg" alt="200x65" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite**</strong>
            Fixformat: 98 x 260 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">13.979,68</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">10.025,08</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">6.131,32</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">14.790,88</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">10.464,48</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">6.401,72</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/8 Seite</strong>
            Fixformat: 47 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.791,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.002,05</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.224,45</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">2.953,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.089,80</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.278,45</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/4 Seite</strong>
            Fixformat: 98 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">5.583,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">4.004,10</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.448,90</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">5.907,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">4.179,60</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.556,90</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/2 Seite</strong>
            Fixformat: 200 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">11.167,20</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">8.008,20</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.897,80</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">11.815,20</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">8.359,20</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">5.113,80</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/1 Seite</strong>
            Fixformat: 200 x 275 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">22.748,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">16.313,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">9.977,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">24.068,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">17.028,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">10.417,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Top-Tipps</strong>
            <small>Reise (MO), Shopping (DI), Veranstaltungen (MI, FR), Essen &amp; Trinken (DO)</small><br />
            Fixformat: 65 x 80 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">1.153,21</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">869,82</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">520,68</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">-</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">-</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">-</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-65x80.svg" alt="65x80" class="img-fluid " />
      </div>
    </div>
    <div class="info-txt-tabelle text-center">* Sport, Kultur, Familie, Gesundheit, Reise ** Sport, Kultur</div>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 5% Werbeabgabe und 20% Umsatzsteuer.</div>

    <!-- -->

    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/hauptblatt/Basis-Platzierung.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarife Basisplatzierung
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>300 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
    <Modal></Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Basisplatzierung',
  components: {
    Modal,
  },
});
</script>
