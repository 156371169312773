<style lang="scss">
.topplatzierung-politik {
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .image-box {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
</style>

<template>
  <div class="topplatzierung-politik content container">
    <h1>Top-platzierung Politik</h1>
    <p class="intro-txt">
      Eine glaubwürdige und seriöse Berichterstattung, wie dies die Kleine Zeitung bietet, sorgt dafür, dass auch Ihre Inserate in diesem Umfeld als glaubwürdig und seriös wahrgenommen werden. Zusätzlich profitieren Sie vom anzeigenfreien Raum vor und nach der Top-Platzierung Politik.
    </p>
    <div class="hinweis-box">Haben Sie übrigens gewusst, dass sich 71,9% * unserer Leser besonders für Politik interessieren?<br /><small>MA 2019, Erhebungszeitraum Jänner bis Dezember 2019</small></div>

    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/topplatzierung-politik.jpg" alt="Topplatzierung Politik" class="img-fluid" />
    </div>

    <div class="whitespace"></div>
    <h2>Ihre Werbemöglichkeiten</h2>
    <div class="tabelle-box tabelle-width-50">
      <div class="headline">
        Millimeterpreis 4C
      </div>
      <div class="row head-row hide-small">
        <div class="col-lg-3"></div>
        <div class="col-lg-3 text-align-center">Kombination</div>
        <div class="col-lg-3 text-align-center">Steiermark</div>
        <div class="col-lg-3 text-align-center">Kärnten</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-center">MO - FR</div>
        <div class="col-6 text-align-center visible-small">Kombination</div>
        <div class="col-lg-3 col-6 text-align-center">36,51</div>
        <div class="col-6 text-align-center visible-small">Steiermark</div>
        <div class="col-lg-3 col-6 text-align-center">26,18</div>
        <div class="col-6 text-align-center visible-small">Kärnten</div>
        <div class="col-lg-3 col-6 text-align-center">16,04</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-center">SA - SO</div>
        <div class="col-6 text-align-center visible-small">Kombination</div>
        <div class="col-lg-3 col-6 text-align-center">38,47</div>
        <div class="col-6 text-align-center visible-small">Steiermark</div>
        <div class="col-lg-3 col-6 text-align-center">27,42</div>
        <div class="col-6 text-align-center visible-small">Kärnten</div>
        <div class="col-lg-3 col-6 text-align-center">16,80</div>
      </div>
    </div>

    <div class="row image-box">
      <div class="col-lg-4">
        <img src="@/assets/img/img-content/hauptblatt/topplatzierung-politik-v1.png" alt="Top-Platzierung Politik" class="img-fluid" />
      </div>
      <div class="col-lg-4">
        <img src="@/assets/img/img-content/hauptblatt/topplatzierung-politik-v2.png" alt="Top-Platzierung Politik" class="img-fluid" />
      </div>
      <div class="col-lg-4">
        <img src="@/assets/img/img-content/hauptblatt/topplatzierung-politik-v3.png" alt="Top-Platzierung Politik" class="img-fluid" />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/8 Seite</strong>
            Fixformat: 47 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">4.928,85</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.534,30</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.165,40</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">5.193,45</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">3.701,70</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.268,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/4 Seite</strong>
            Fixformat: 98 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.857,70</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">7.068,60</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.330,80</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">10.386,90</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">7.403,40</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.536,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/3 Seite</strong>
            Fixformat: 200 x 90 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">13.143,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">9.424,80</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">5.774,40</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">13.849,20</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">9.871,20</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">6.048,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x90.svg" alt="200x90" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/2 Seite</strong>
            Fixformat: 200 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">19.715,40</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">14.137,20</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">8.661,60</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">20.773,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">14.806,80</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">9.072,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x90.svg" alt="200x90" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Junior Page</strong>
            Fixformat: 149 x 200 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">21.906,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">15.708,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">9.624,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">23.082,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">16.452,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">10.080,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x200.svg" alt="149x200" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/1 Seite</strong>
            Fixformat: 200 x 275 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">40.161,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">28.798,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">17.644,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">42.317,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">30.162,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">18.480,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
      </div>
    </div>

    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
    <div class="download-pdf">
      <div class="line"><span>Download</span></div>
      <a href="../../pdf/hauptblatt/Top-Platzierung-Politik.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Topplatzierung Politik
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>70 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
    <Modal>teadsfasdfasdfasst</Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'TopplatzierungPolitik',
  components: {
    Modal,
  },
});
</script>
