<style lang="scss">
.themenplatzierung {
  .format-box {
    margin-bottom: 20px;
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
    }
  }
  .hinweis-box {
    padding: 15px;
    background: #eee;
  }
  .karte-bild-box {
    height: 350px;
    img {
      display: block;

      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .karte-stmk {
      width: 90%;
    }
  }
  .image-box {
    margin: 50px 0;
  }

  .themen-box {
    margin: 50px 0;
    .col-lg-3 {
      margin-bottom: 30px;
      .bild-box {
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 300px;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background-color: rgba($color: #000000, $alpha: 0.5);
          -webkit-transition: all 500ms ease;
          -moz-transition: all 500ms ease;
          -ms-transition: all 500ms ease;
          -o-transition: all 500ms ease;
          transition: all 500ms ease;
        }
        .txt {
          width: 100%;
          position: absolute;
          text-align: center;
          left: 0;
          top: 50%;
          z-index: 2;
          color: #fff;
          font-size: 200%;
          height: 40px;
          margin-top: -20px;
          span {
            display: block;
            font-size: 50% !important;
            padding: 0 20px;
          }
        }
        &:hover {
          &:after {
            background-color: rgba($color: #000000, $alpha: 0);
          }
          .txt {
            opacity: 0;
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="themenplatzierung content container">
    <h1>Themenplatzierung</h1>
    <p class="intro-txt"><strong>Der passende Rahmen für Ihre Werbebotschaft.</strong> Profitieren Sie von der thematischen und inhaltlichen Vielfalt an sieben Tagen der Woche und platzieren Sie Ihre Inhalte in jenem Umfeld, für das sich Ihre Zielgruppe besonders interessiert.</p>

    <div class="themen-box">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/themen-international.jpg')})` }">
            <div class="txt">International</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/themen-oesterreich.jpg')})` }">
            <div class="txt">Österreich</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/themen-bundesland.jpg')})` }">
            <div class="txt">Bundesland</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="bild-box" :style="{ 'background-image': `url(${require('@/assets/img/img-content/hauptblatt/themen-wirtschaft.jpg')})` }">
            <div class="txt">Wirtschaft<span>Keine Erscheinung am Sonntag</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="whitespace"></div>
    <h2>Ihre Werbemöglichkeiten</h2>

    <div class="tabelle-box tabelle-width-50">
      <div class="headline">
        <strong>Millimeterpreis 4c</strong>
      </div>
      <div class="row head-row hide-small">
        <div class="col-lg-3"></div>
        <div class="col-lg-3 text-align-right">Kombination</div>
        <div class="col-lg-3 text-align-right">Steiermark</div>
        <div class="col-lg-3 text-align-right">Kärnten</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-center">MO - FR</div>
        <div class="col-6 text-align-left visible-small">Kombination</div>
        <div class="col-lg-3 col-6 text-align-right">26,39</div>
        <div class="col-6 text-align-left visible-small">Steiermark</div>
        <div class="col-lg-3 col-6 text-align-right">18,77</div>
        <div class="col-6 text-align-left visible-small">Kärnten</div>
        <div class="col-lg-3 col-6 text-align-right">11,49</div>
      </div>
      <div class="row content-row">
        <div class="col-lg-3 text-align-center">SA - SO</div>
        <div class="col-6 text-align-left visible-small">Kombination</div>
        <div class="col-lg-3 col-6 text-align-right">27,81</div>
        <div class="col-6 text-align-left visible-small">Steiermark</div>
        <div class="col-lg-3 col-6 text-align-right">19,65</div>
        <div class="col-6 text-align-left visible-small">Kärnten</div>
        <div class="col-lg-3 col-6 text-align-right">12,02</div>
      </div>
    </div>

    <div class="row image-box">
      <div class="col-md-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/hauptblatt/themenplatzierung-oesterreich-v1.png" alt="Themenplatzierung Österreich" class="img-fluid " />
      </div>
      <div class="col-md-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/hauptblatt/themenplatzierung-wirtschaft-v1.png" alt="Themenplatzierung Wirtschaft" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 47 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">1.543,82</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.098,05</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">672,17</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">1.626,89</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">1.149,53</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">703,17</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 98 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">3.087,63</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.196,09</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.344,33</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">3.253,77</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.299,05</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.406,34</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 200 x 45 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">6.175,26</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">4.392,18</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.688,66</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">6.507,54</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">4.598,10</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.812,68</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x45.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 98 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.262,89</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.588,27</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.032,99</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.761,31</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.897,15</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.219,02</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="47x45" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 47 x 260 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">8.919,82</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.344,26</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.883,62</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.399,78</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.641,70</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.062,76</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47 x 260" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 149 x 90 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.262,89</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.588,27</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.032,99</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.761,31</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.897,15</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.219,02</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x90.svg" alt="149x90" class="img-fluid " />
      </div>
    </div>
    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite*</strong>
            Fixformat: 200 x 65 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">8.919,82</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.344,26</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.883,62</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.399,78</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.641,70</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.062,76</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x65.svg" alt="200x65" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Aufschlagseite**</strong>
            Fixformat: 98 x 260 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">17.839,64</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">12.688,52</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">7.767,24</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">18.799,56</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">13.283,40</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">8.125,52</div>
          </div>
          <div class="info-txt-tabelle">Keine Erscheinung am Sonntag möglich.</div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/8 Seite</strong>
            Fixformat: 47 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">3.562,65</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.533,95</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.551,15</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">3.754,35</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">2.652,75</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">1.622,70</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/4 Seite Quer</strong>
            Fixformat: 98 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.125,30</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.067,90</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.102,30</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.508,70</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.305,50</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.245,40</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/4 Seite hoch</strong>
            Fixformat: 47 x 260 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">6.861,40</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">4.880,20</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">2.987,40</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">7.230,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">5.109,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">3.125,20</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x260.svg" alt="47x260" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/3 Seite</strong>
            Fixformat: 200 x 90 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">9.500,40</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">6.757,20</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.136,40</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">10.011,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">7.074,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">4.327,20</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x90.svg" alt="200x90" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/2 Seite Quer</strong>
            Fixformat: 200 x 135 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">14.250,60</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">10.135,80</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">6.204,60</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">15.017,40</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">10.611,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">6.490,80</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/2 Seite Hoch</strong>
            Fixformat: 98 x 260 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">13.722,80</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">9.760,40</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">5.974,80</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">14.461,20</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">10.218,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">6.250,40</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x260.svg" alt="98x260" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Junior Page</strong>
            Fixformat: 149 x 200 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">15.834,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">11.262,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">6.894,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">16.686,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">11.790,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">7.212,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-149x200.svg" alt="149x200" class="img-fluid " />
      </div>
    </div>

    <div class="row format-box">
      <div class="col-lg-7 order-lg-2">
        <div class="tabelle-box">
          <div class="headline">
            <strong>1/1 Seite</strong>
            Fixformat: 200 x 275 mm
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">29.029,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">20.647,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">12.639,00</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">30.591,00</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">21.615,00</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">13.322,00</div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x275" class="img-fluid " />
      </div>
    </div>

    <div class="info-txt-tabelle text-center">* Bundesland, Wirtschaft ** Wirtschaft (Am Sonntag kein Wirtschaftsteil)</div>

    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, zuzüglich 5% Werbeabgabe und 20% Umsatzsteuer.</div>

    <!-- -->

    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/hauptblatt/Themen-Platzierung.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Themenplatzierung
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>64 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
    <Modal>teadsfasdfasdfasst</Modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Modal from '@/components/util/Modal.vue';

export default defineComponent({
  name: 'Themenplatzierung',
  components: {
    Modal,
  },
});
</script>
